<template>
  <validation-observer
    ref="approvalResearchSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="approvalResearchSaveModal"
      centered
      title="Phê duyệt đề tài"
      :no-close-on-backdrop="true"
      body-class="position-static"
      @hide="onHide"
    >
      <b-form>
        <b-form-group
          label="Đánh giá"
          label-for="status"
        >
          <validation-provider
            #default="{ errors }"
            name="Đánh giá"
            rules="required"
          >
            <v-select
              v-model="evaluation"
              label="label"
              :options="evaluationOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave()"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('approvalResearchSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>

      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      /></b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BModal,
  BOverlay, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { RESEARCH_EVALUATIONS } from '@/const/status'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ApprovalResearchSave',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    BFormInvalidFeedback,
    BOverlay,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
  },
  props: {
    researchId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      evaluation: null,
    }
  },
  computed: {
    evaluationOptions() {
      return RESEARCH_EVALUATIONS
    },
  },
  methods: {
    ...mapActions({
      approvedResearch: 'approvalResearch/approvedResearch',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onHide() {
      this.status = null
    },
    async onSave() {
      this.isLoading = true
      try {
        const response = await this.approvedResearch({ id: this.researchId, params: { evaluation: this.evaluation } })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('approvalResearchSaveModal')
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
